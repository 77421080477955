<template>
    <div class="gy-main-layout" style="">
        <Navbar class="gy-navigation" />
        <router-view />
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/app/Navbar'
import Footer from '@/components/app/Footer'

export default {
    name: 'main-layout',
    components: {
        Navbar, Footer
    }
}
</script>

<style lang="scss" scoped>
    .gy-main-layout {
        z-index: -1;
        background: #050719;
        max-width: 1440px; 
        min-width: 340px;
        // width: 1440px; 
        // height: 8792px;
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        margin: 0 auto;
        padding: 0;
    }

    .gy-navigation {
        z-index: 2;
    }
</style>