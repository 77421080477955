<template>
    <a :class="'gy-button gy-button--' + size" :href="url" target="_blank" rel="noopener">
        <slot />
    </a>
</template>

<script>
export default {
	name: 'gy-button',
    props: ['url', 'size']
}
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/fonts.lib.scss";

    .gy-button {
        @include gy-font-h5(); 

        // margin: 0 auto;
        width: min-content;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        border: none;
        border-radius: 32px;
        background: linear-gradient(90deg, #445EB6 0%, #6540D6 100%);
        color: #F7F8F8;
        text-decoration: none;

        &--small {
            padding: 8px 24px;
        }

        &--normal {
            padding: 18px 42px;
            font-size: 24px;
        }
    }
</style>
