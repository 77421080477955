<template>
    <footer class="gy-footer">
        <!-- <div class="gy-footer"> -->
            <router-link class="gy-footer__logo" to="/">
                <img src="@/assets/icons/logo-guardyoo.svg" alt="GuardYoo"/>
            </router-link>
            <div class="gy-footer__logo-name">
                <router-link to="/">
                    <div>GuardYoo</div>
                </router-link>
            </div>
            <div class="gy-footer__solutions">
                <li
                    v-for="solution in solutionList"
                    :key="solution.url"
                >
                    <router-link :to="solution.url">{{ solution.title }}</router-link>
                </li>
            </div>
            <div class="gy-footer__menuList">
                <li
                    v-for="menu in menuList"
                    :key="menu.url"
                >
                    <router-link :to="menu.url">{{ menu.title }}</router-link>
                </li>
            </div>
            <div class="gy-footer__info">
                <a href="https://linkedin.com/company/guardyoo?trk=public_profile_topcard-current-company" target="_blank" rel="noopener">
                    <img src="@/assets/icons/linkedin.svg" alt="linkedin"/>
                    <span>GuardYoo</span>
                </a>
                <div>
                    <img src="@/assets/icons/email.svg" alt="email"/>
                    <span>info@guardyoo.com</span>
                </div>
                <a 
                    href="https://www.google.com/maps/search/Penrose+Wharf+Business+Centre,14+Penrose+Wharf,+Cork++++++++++++++++++++Centre,14+Penrose+Wharf,+Cork,++++++++++++++++++++++++++IrelandT23EK0E/@51.9002231,-8.4631075,19z?authuser=0" 
                    target="_blank" 
                    rel="noopener"
                >
                    <img src="@/assets/icons/location.svg" alt="location"/>
                    <span>
                        GuardYoo, Penrose Wharf Business 
                        Centre,14 Penrose Wharf, Cork, 
                        IrelandT23EK0E
                    </span>
                </a>
            </div>
            <div class="gy-footer__docs">
                <router-link to="/terms-of-service">
                    <div>Terms of Service</div>
                </router-link>
                <router-link to="/privacy-policy">
                    <div>Privacy Policy</div>
                </router-link>
            </div>
    </footer>
</template>

<script>
export default {
    name: 'gy-footer',
    data() {
        return {
            showSolutions: false,
            menuList: [
                {title: 'About GuardYoo', url: '/about-guardyoo'},
                {title: 'Knowledge base', url: '/blog'}
            ],
            solutionList: [
                {title: 'For Managed Service Provider', url: '/cyber-security-solution-for-msp'},
                {title: 'For Enterprise', url: '/cyber-security-solution-for-enterprise'},
                {title: 'For Small-Medium Business', url: '/cyber-security-solution-for-sme'},
                {title: 'For Insurance', url: '/cyber-security-solution-for-insurance'}
            ]

        }
    }
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/footer.scss";
</style>
