import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import components from '@/components/ui'
import { createHead } from '@vueuse/head'
import VueGtag from 'vue-gtag'

const app = createApp(App);
const head = createHead();

components.forEach(component => {
    app.component(component.name, component)
});

app.use(head).use(router)
.use(VueGtag, {
    config: {
        id: 'G-GFHHK05VWX'
    }
})
.mount('#app');
