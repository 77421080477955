<template>
    <div class="gy-card">
        <slot />
    </div>
</template>

<script>
export default {
	name: 'gy-card'
}
</script>

<style lang="scss" scoped>
    .gy-card {
        // margin: 0 auto;
        // width: min-content;
        // cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // white-space: nowrap;
        border: 1px solid #515369;
        border-radius: 16px;
    }
</style>
