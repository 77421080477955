<template>
    <nav>
        <div class="gy-nav" :class="{'gy-nav__solutions-show': showSolutions}">
            <ul class="gy-nav__list" ref="menuSolutions">
                <li class="gy-nav__list__button" @click="closeSolutions()">
                    <router-link to="/"><img src="@/assets/icons/logo-guardyoo.svg" alt="GuardYoo"/></router-link>
                </li>
                <li class="gy-nav__list__button" @click="openSolutions()">
                    <div :class="{'gy-nav__list__button__showSolutions': showSolutions}">
                        Solutions
                    </div>
                </li>
                <li
                    v-for="menu in menuList"
                    :key="menu.url"
                    class="gy-nav__list__button" 
                    @click="closeSolutions()"
                >
                    <router-link :to="menu.url">{{ menu.title }}</router-link>
                </li>
            </ul>
            <gy-button url="https://app.guardyoo.com/auth/login" size="small">Log In</gy-button>
        </div>
        <ul class="gy-nav__list__solutions" v-if="showSolutions" ref="solutions">
            <li
                v-for="solution in solutionList"
                :key="solution.url"
                class="" 
                @click="closeSolutions()"
            >
                <router-link :to="solution.url">{{ solution.title }}</router-link>
            </li>
        </ul>

        <!-- if @media screen and (max-width: 576px) >>> the following code will be displayed-->
        <div class="gy-nav-small" :class="{'gy-nav-small__showMenu': showMenu}" ref="menuHead">
            <router-link to="/" @click="closeMenu()"><img src="@/assets/icons/logo-guardyoo.svg" alt="GuardYoo"/></router-link>
            <button @click.stop="openMenu()">
                <img v-if="!showMenu" ref="menuHead" src="@/assets/icons/menu.svg" alt="GuardYoo"/>
                <img v-if="showMenu" src="@/assets/icons/close.svg" alt="GuardYoo"/>
            </button>
        </div>
        <ul class="gy-nav-small__menu" v-if="showMenu" ref="menu">
            <li><gy-button url="https://app.guardyoo.com/auth/login" size="small">Log In</gy-button></li>
            <li class="gy-nav-small__menu__solutions-title">Solutions</li>
            <li
                v-for="solution in solutionList"
                :key="solution.url"
                class="gy-nav-small__menu__solutions" 
                @click="closeMenu()"
            >
                <router-link :to="solution.url">{{ solution.title }}</router-link>
            </li>
            <li
                v-for="menu in menuList"
                :key="menu.url"
                class="gy-nav-small__menu__list" 
                @click="closeMenu()"
            >
                <router-link :to="menu.url">{{ menu.title }}</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'nav-bar',
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    data() {
        return {
            showSolutions: false,
            showMenu: false,
            menuList: [
                {title: 'About', url: '/about-guardyoo'},
                {title: 'Knowledge base', url: '/blog'}
            ],
            solutionList: [
                {title: 'For MSP', url: '/cyber-security-solution-for-msp'},
                {title: 'For Enterprise', url: '/cyber-security-solution-for-enterprise'},
                {title: 'For Insurance', url: '/cyber-security-solution-for-insurance'},
                {title: 'For Small Businesses', url: '/cyber-security-solution-for-sme'}
            ]

        }
    },
    methods: {
        openSolutions() {
            this.showSolutions = !this.showSolutions;
        },
        closeSolutions() {
            this.showSolutions = false;
        },
        openMenu() {
            this.showMenu = !this.showMenu;
        },
        closeMenu() {
            this.showMenu = false;
        },
        handleClickOutside(event) {
            // check whether it was clicked outside of the Solutions and Menu element
            let el = event.target;
            let insideSolutions = false;
            let insideMenuSolutions = false;
            let insideMenu = false;
            let insideMenuHead = false;

            while (el) {
                if (el === this.$refs.solutions) {
                    insideSolutions = true;
                    break;
                }
                if (el === this.$refs.menuSolutions) {
                    insideMenuSolutions = true;
                    break;
                }
                if (el === this.$refs.menu) {
                    insideMenu = true;
                    break;
                }
                if (el === this.$refs.menuHead) {
                    insideMenuHead = true;
                    break;
                }
                el = el.parentNode;
            }
            if (!insideSolutions && !insideMenuSolutions) {
                if(this.showSolutions === true) {
                    this.closeSolutions();
                }
            }
            if (!insideMenu && !insideMenuHead) {
                if(this.showMenu === true) {
                    this.closeMenu();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/navbar.scss";
</style>
