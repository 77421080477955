<template>
	<div id="app">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import { useHead } from '@vueuse/head';
import {computed, reactive } from 'vue';

export default {
	setup() {
		const siteData = reactive({
			title: `Automated Risk Assessment Platform | GuardYoo`,
			description: `A cybersecurity solution for an \"inside-out\" cyber risk assessment that automates the time-intensive & expensive process of data collection & analysis.`,
		})
		useHead({
			title: computed(() => siteData.title),
			meta: [{
				name: `description`,
				content: computed(() => siteData.description)
			}]
		})
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || 'main') + '-layout'
		}
	},
	components: {
		MainLayout
	}
}
</script>

<style lang="scss">
#app {
	font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//   text-align: center;
	color: #F7F8F8;
	background: #050719;
	cursor: default;
	min-height: 100vh;
}

html {
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: #18123f #050719;
	// scrollbar-color: #ddd #fff;
}

html::-webkit-scrollbar {
  	width: 10px;
}

html::-webkit-scrollbar-track {
  	background-color: #050719;
}

html::-webkit-scrollbar-thumb {
	-webkit-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #18123f;
  	background-color: transparent;
}

/* Edge */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: #ddd #fff;
  }

  html::-webkit-scrollbar {
    width: 8px;
  }

  html::-webkit-scrollbar-track {
    background-color: #050719;
  }

  html::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 1px solid #18123f;
    background-color: transparent;
  }
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@font-face {
	font-family: "Inter";
	src: local("Inter"),
	// url(@/assets/fonts/Inter-Black.ttf) format("truetype");
	// url(@/assets/fonts/Inter-Bold.ttf) format("truetype");
	// url(@/assets/fonts/Inter-ExtraBold.ttf) format("truetype");
	// url(@/assets/fonts/Inter-ExtraLight.ttf) format("truetype");
	// url(@/assets/fonts/Inter-Light.ttf) format("truetype");
	// url(@/assets/fonts/Inter-Medium.ttf) format("truetype");
	// url(@/assets/fonts/Inter-Regular.ttf) format("truetype");
	// url(@/assets/fonts/Inter-SemiBold.ttf) format("truetype");
	// url(@/assets/fonts/Inter-Thin.ttf) format("truetype");
	url(@/assets/fonts/Inter-VariableFont.ttf) format("truetype");
}
</style>
