import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {layout: 'main'},
		component: () => import('../views/Home.vue'),
		alias: '/home'
	},
	{
		path: '/cyber-security-solution-for-msp',
		name: 'msp',
		meta: {layout: 'main'},
		component: () => import('../views/MSP.vue'),
		alias: '/partners'
	},
	{
		path: '/cyber-security-solution-for-enterprise',
		name: 'enterprise',
		meta: {layout: 'main'},
		component: () => import('../views/Enterprise.vue')
	},
	{
		path: '/cyber-security-solution-for-insurance',
		name: 'insurance',
		meta: {layout: 'main'},
		component: () => import('../views/Insurance.vue')
	},
	{
		path: '/cyber-security-solution-for-sme',
		name: 'sme',
		meta: {layout: 'main'},
		component: () => import('../views/SME.vue')

	},
	{
		path: '/about-guardyoo',
		name: 'about',
		meta: {layout: 'main'},
		component: () => import('../views/About.vue'),
		alias: '/about'
	},
	{
		path: '/blog',
		name: 'blog',
		meta: {layout: 'main'},
		component: () => import('../views/Blog.vue'),
		alias: '/news'
	},
	{
		path: '/blog/:id',
		name: 'blog-post',
		meta: {layout: 'main'},
		component: () => import('../views/BlogPost.vue'),
		beforeEnter: (to, from, next) => {
			if(blogRedirectPath.some(path => path.id === to.params.id)) {
				blogRedirectPath.forEach(path => {
				if (to.params.id === path.id) next({ path: path.redirectTo })
				})
			} else {
				next();
			}
		}
	},
	{
		path: '/terms-of-service',
		name: 'TermsOfService',
		meta: {layout: 'main'},
		component: () => import('../views/TermsOfService.vue'),
		alias: '/terms-of-use'
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		meta: {layout: 'main'},
		component: () => import('../views/PrivacyPolicy.vue')
	},
	{
		path: '/:catchAll(.*)',
		name: 'ErrorPage',
		meta: {layout: 'main'},
		component: () => import('../views/ErrorPage.vue')
	}
]

const blogRedirectPath = [
	{id: 'remote-compromise-assessment', redirectTo: '/blog/automated-compromise-assessment'},
	{id: 'what-the-ceo-can-do-to-make-their-business-more-cybersecure', redirectTo: '/blog/ways-to-improve-cybersecurity'},
	{id: 'how-to-manage-all-your-cybersecurity-clients-on-a-single-platform', redirectTo: '/cyber-security-solution-for-msp'},
	{id: 'it-manager-vs.-security-officer-whats-the-difference', redirectTo: '/'},
	{id: 'struggling-to-fill-cyber-positions', redirectTo: '/'},
	{id: 'remote-compromise-assessment-breach', redirectTo: '/blog/breach-invistigation-and-response'},
	{id: 'model-behaviour', redirectTo: '/blog/cyber-security-threads'},
	{id: 'remote-compromise-assessment-why', redirectTo: '/blog/automated-compromise-assessment'},
	{id: 'cyber-security-consultant1', redirectTo: '/cyber-security-solution-for-msp'},
	{id: 'remote-working-post-covid19', redirectTo: '/blog/cyber-security-threads'},
	{id: 'updating-the-board-on-cyber-issues', redirectTo: '/blog/cyber-security-threads'},
	{id: 'covid-19', redirectTo: '/blog/cyber-security-threads'},
	{id: 'remote-compromise-assessment-overview', redirectTo: '/blog/automated-compromise-assessment'},
	{id: 'the-challenge-of-underwriting-cyber-insurance', redirectTo: '/cyber-security-solution-for-insurance'}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
})

export default router
